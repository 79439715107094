import React, { useState, Suspense, useContext, useEffect } from "react";
import {
  DashboardOutlined,
  UserOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Row, Col, Select, Space, Dropdown } from "antd";
import logo1 from "../logo.png";
import patientimg from "../patient.jpg";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import GlobalSpinner from "../features/CommonComponents/GlobalSpinner/GlobalSpinner";
import routes from "./routes";
import { Context } from "../components/Wrapper";
import { FormattedMessage } from "react-intl";
const { Header, Content, Footer, Sider } = Layout;
function ProtectedRoutes() {
  const location = useLocation();
  const context = useContext(Context);
  const [collapsed, setCollapsed] = useState(true);
  const userFirstName = localStorage.getItem("userFirstName");
  const userLastName = localStorage.getItem("userLastName");
  const userRoles = localStorage.getItem("roles");
  const handleLogOut = () => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    const selectedLanguage = localStorage.getItem("selectedlanguage");
    localStorage.clear();
    if (lastVisitedRoute) {
      localStorage.setItem("lastVisitedRoute", lastVisitedRoute);
    }
    if (selectedLanguage) {
      localStorage.setItem("selectedlanguage", selectedLanguage);
    }
    window.location.reload(true);
  };
  const items = [
    // {
    //   label: (
    //     <a href="">
    //       <FormattedMessage id="Common.MyProfile" />
    //     </a>
    //   ),
    //   key: "0",
    // },
    // {
    //   label: (
    //     <a href="">
    //       <FormattedMessage id="Common.Settings" />
    //     </a>
    //   ),
    //   key: "1",
    // },

    {
      label: (
        <a onClick={() => handleLogOut()}>
          <FormattedMessage id="Common.Logout" />
        </a>
      ),
      key: "3",
    },
  ];

  const handleKey = () => {
    const locName = location.pathname;
    if (locName?.includes("patients")) {
      return "2";
    } else if (locName?.includes("clients")) {
      return "3";
    } else {
      return "1";
    }
  };
  useEffect(() => {
    if (location.pathname != "/login") {
      localStorage.setItem("lastVisitedRoute", location.pathname);
    }
  }, [location]);
  return (
    <>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="demo-logo-vertical text-center">
            <img src={logo1} style={{ height: "60px" }} alt="" />
          </div>
          <Menu
            theme="light"
            mode="inline"
            // defaultSelectedKeys={["1"]}
            defaultSelectedKeys={[handleKey()]}
          >
            <Menu.Item key="1" icon={<DashboardOutlined />}>
              <Link to="/chyroDashboard">
                <span>
                  <FormattedMessage id="Common.Dashboard" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<TeamOutlined />}>
              <Link to="/patients">
                <span>
                  <FormattedMessage id="Common.Patients" />
                </span>
              </Link>
            </Menu.Item>
            {userRoles.includes("Admin") && (
              <Menu.Item key="3" icon={<UserOutlined />}>
                <Link to="/clients">
                  <span>
                    <FormattedMessage id="Common.Admin" />
                  </span>
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{
              padding: "0 20px",
              background: "#fff",
            }}
          >
            <Row className="header-part" justify="end">
              <Col span={6} className="text-right">
                <Space>
                  <Select
                    value={context.locale}
                    onChange={context.selectLang}
                    variant="borderless"
                    defaultValue="en"
                    options={[
                      {
                        value: "en-US",
                        label: "English",
                      },
                      {
                        value: "fr-FR",
                        label: "French",
                      },
                    ]}
                  />
                  <Dropdown
                    menu={{
                      items,
                    }}
                    trigger={["click"]}
                    arrow={{
                      pointAtCenter: true,
                    }}
                    placement="bottom"
                    className="herader-profile"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        <img src={patientimg} className="header-avtar" />

                        <div>
                          <p>{`${userFirstName} ${userLastName}`}</p>
                          {/* <span>Neurology</span> */}
                        </div>
                      </Space>
                    </a>
                  </Dropdown>
                </Space>
              </Col>
            </Row>
          </Header>
          <Content>
            <div
              style={{
                padding: 20,
                minHeight: 360,
                background: "#fff",
                position: "relative",
              }}
            >
              <div className="main-div-block">
                {/* <Dashboard /> */}
                <Routes>
                  {routes.map(
                    ({ component: Component, path, exact }, index) => (
                      <Route
                        path={`/${path}`}
                        key={index}
                        exact={exact}
                        element={
                          <Suspense
                            fallback={
                              <div>
                                <span>
                                  <FormattedMessage id="Common.Loading" />
                                </span>
                              </div>
                            }
                          >
                            <Component />
                          </Suspense>
                        }
                      >
                        {/* <GlobalSpinner
                          appContainer={<Component />}
                        ></GlobalSpinner> */}
                        {/* <div className={`fg ${isActiveMenu ? "shown" : ""}`}>
                          <SideMenu />
                        </div> */}
                      </Route>
                    )
                  )}
                </Routes>
              </div>
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            <FormattedMessage id="Common.Copyright" /> ©{" "}
            {new Date().getFullYear()}{" "}
            <FormattedMessage id="Common.AllRightsReserved" />
          </Footer>
        </Layout>
      </Layout>
    </>
  );
}

export default ProtectedRoutes;
