import { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Navigate,
  Routes,
  Outlet,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import ReactDOM from "react-dom";
import LoginPage from "./features/OnBoarding/LoginPage";
import ChyroDashboard from "./features/Dashboard/ChyroDashboard";
import "./index.css";
import { loginSessionAvailable } from "./features/OnBoarding/AuthenticationSlice";
import ResultPopup from "./features/CommonComponents/ResultPopup/ResultPopup";
const PatientList = lazy(() =>
  import("./features/Patients/PatientList/PatientList")
);
const ClientList = lazy(() =>
  import("./features/Clients/ClientList/ClientList")
);
const ViewPatient = lazy(() =>
  import("./features/Patients/Viewpatient/Viewpatient")
);
const ViewClient = lazy(() =>
  import("./features/Clients/ViewClient/ViewClient")
);
const SinglePatientView = lazy(() =>
  import("./features/Patients/SinglePatientView/SinglePatientView")
);
const VistThermalPostureCompare = lazy(() =>
  import(
    "./features/Patients/VistThermalPostureCompare/VistThermalPostureCompare"
  )
);
const CompareHealthReport = lazy(() =>
  import("./features/Patients/Viewpatient/CompareHealthReport")
);
const PatientVisitDetails = lazy(() =>
  import("./features/Patients/PatientVisitDetails/PatientVisitDetails")
);
function App() {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const isAuthenticatedState = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const userRoles = localStorage.getItem("roles");
  useEffect(() => {
    if (!isAuthenticatedState) {
      var isAuthenticateStorage = localStorage.getItem("isAuthenticated");
      var token_bearer = localStorage.getItem("token_bearer");
      setIsAuthenticated(isAuthenticateStorage);
      if (isAuthenticateStorage && token_bearer) {
        setIsAuthenticated(true);
        dispatch(loginSessionAvailable());
      } else {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(isAuthenticatedState);
    }
  }, [isAuthenticatedState, dispatch]);

  return (
    <>
      {isAuthenticated != null && (
        <BrowserRouter>
          <ResultPopup />
          <Routes>
            {/* <Route path="/login" element={<LoginPage />} /> */}

            <Route element={<PublicRoutes isAuthenticated={isAuthenticated} />}>
              <Route path="/login" element={<LoginPage />} />
            </Route>

            <Route
              element={<PrivateRoutes isAuthenticated={isAuthenticated} />}
            >
              <Route path="/chyroDashboard" element={<ChyroDashboard />} />
              <Route path="/patients" element={<PatientList />} />
              <Route
                path="/patients/viewpatient/:PatientId"
                element={<ViewPatient />}
              />
              <Route
                path="/patients/viewpatient/:PatientId/PatientVisitDetails/:visitId"
                element={<PatientVisitDetails />}
              />
              <Route
                path="/singleviewpatient"
                element={<SinglePatientView />}
              />
              <Route
                path="/patients/viewpatient/:PatientId/postureCompare"
                element={<VistThermalPostureCompare />}
              />
              <Route
                path="/patients/viewpatient/:PatientId/healthcompare"
                element={<CompareHealthReport />}
              />
              {userRoles?.includes("Admin") && (
                <Route path="/clients" element={<ClientList />} />
              )}
              {userRoles?.includes("Admin") && (
                <Route
                  path="/clients/viewclient/:clientId"
                  element={<ViewClient />}
                />
              )}
            </Route>

            <Route
              path="/"
              element={
                <Navigate to={isAuthenticated ? "/chyroDashboard" : "/login"} />
              }
            />
            <Route
              path="*"
              element={
                <Navigate to={isAuthenticated ? "/chyroDashboard" : "/login"} />
              }
            />
          </Routes>

          {/* <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/menu" element={<ProtectedRoutes />} />
          <Route path="/chyroDashboard" element={<ChyroDashboard />} />
          <Route path="/patients" element={<Patients />} />
          <Route path="/viewpatient" element={<Viewpatient />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/viewclient" element={<ViewClient />} />
        </Routes> */}
        </BrowserRouter>
      )}
    </>
  );
}

function PublicRoutes({ isAuthenticated }) {
  const location = useLocation();
  return !isAuthenticated ? (
    <LoginPage />
  ) : (
    <Navigate
      to={{
        pathname: localStorage.getItem("lastVisitedRoute") || "/chyroDashboard",
        state: { from: location },
      }}
    />
  );
}

const PrivateRoutes = ({ isAuthenticated }) => {
  const location = useLocation();
  return isAuthenticated ? (
    <ProtectedRoutes />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default App;
