import { loginSuccess } from "./AuthenticationSlice";
import { postRequest } from "../../app/axiosClient";
import { message } from "antd";
import {
  showLoader,
  hideLoader,
} from "../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";

export const LoginAPI = (values) => (dispatch) => {
  try {
    dispatch(showLoader());
    postRequest(`api/Accounts/Login`, values).then((res) => {
      if (res?.data?.errorResponse) {
        message.error(res?.data?.errorResponse?.errorMessage);
      } else {
        dispatch(loginSuccess(res?.data));
        message.success("Vous vous êtes connecté avec succès");
      }
      dispatch(hideLoader());
    });
  } catch {
    dispatch(hideLoader());
    message.error("Impossible de se connecter, veuillez réessayer");
    dispatch(hideLoader());
  }
};
