import { Button, Result, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showOrHideResultPopup } from "../ResultPopup/ResultPopupSlice";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
function ResultPopup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const popupData = useSelector((state) => state.ResultPopup);
  const handleOk = () => {
    dispatch(showOrHideResultPopup(false));
  };

  const handleBackButtonClick = () => {
    dispatch(showOrHideResultPopup(false));
    navigate.push(popupData.backButtonRedirectPath);
  };

  return (
    <>
      <Modal
        //title="success"
        visible={popupData.isModalOpen}
        //visible={true}
        footer={null}
        //onOk={handleOk}
        onCancel={handleOk}
      >
        <Result
          status={popupData.status}
          title={popupData.title}
          subTitle={popupData.subTitle}
          extra={[
            <div key="buttons">
              <Button
                type="primary"
                key="console"
                onClick={handleOk}
                name={"OK"}
              >
                <FormattedMessage id="Common.Ok" />
              </Button>
              <br></br>
              {/* <Button
                 type="primary"
               className="mt-12"
                key="console"
                onClick={handleBackButtonClick}
                name={popupData.backButtonName}
                visible={popupData.showBackButton}
              >
                {popupData.backButtonName}
              </Button> */}
            </div>,
          ]}
        />
      </Modal>
    </>
  );
}

export default ResultPopup;
