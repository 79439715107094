import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  thermalImagesNamesByVisits: [],
};

export const VistThermalPostureCompareSlice = createSlice({
  name: "vistThermalPostureCompare",
  initialState,
  reducers: {
    getThermalImagesNamesByVisits: (state, action) => {
      state.thermalImagesNamesByVisits = action.payload;
    },
  },
});

export const { getThermalImagesNamesByVisits } =
  VistThermalPostureCompareSlice.actions;

export default VistThermalPostureCompareSlice.reducer;
