import { postRequest } from "../../app/axiosClient";
import { message } from "antd";
import { getRecentVisitsRecords } from "./DashboardSlice";
import {
  showLoader,
  hideLoader,
} from "../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import { FormattedMessage } from "react-intl";
import { showResultPopupMessage } from "../CommonComponents/ResultPopup/ResultPopupSlice";

export const getRecentVisitsApi = (values) => (dispatch) => {
  try {
    dispatch(showLoader());
    postRequest(`api/Dashboard/GetRecentPatientsList`, values)
      .then((res) => {
        dispatch(
          getRecentVisitsRecords({
            data: res.data.items,
            totalPages: res.data.totalPages,
          })
        );
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showResultPopupMessage({
            status: "error",
            title: <FormattedMessage id="error.Patient.Patientlistnotfound" />,
          })
        );
      });
  } catch (e) {
    dispatch(hideLoader());
    dispatch(
      showResultPopupMessage({
        status: "error",
        title: <FormattedMessage id="error.Error" />,
      })
    );
  }
};
