import React, { useState, useEffect, PureComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Space,
  Button,
  Dropdown,
  Row,
  Col,
  Input,
  Modal,
  Form,
  DatePicker,
  Radio,
  Select,
  Card,
  Popover,
  QRCode,
  Empty,
} from "antd";
import {
  QrcodeOutlined,
  MoreOutlined,
  UserAddOutlined,
  ControlOutlined,
  FileExcelOutlined,
  PrinterOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import patientimg from "../../patient.jpg";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import QRCodeGenerator from "../CommonComponents/QRCodeGenerator";
import logo from "../../logo.png";
import { getRecentVisitsApi } from "./DashboardAPI";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import HealthScore from "../CommonComponents/HealthScore";

function ChyroDashboard() {
  const intl = useIntl();
  const { Option } = Select;
  const dispatch = useDispatch();
  const RecentVisitsData = useSelector(
    (state) => state.dashboard.PatientListRecords
  );
  const totalPages = useSelector((state) => state.dashboard.totalPages);
  const { Column, ColumnGroup } = Table;
  const { Search } = Input;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [qrCodeValues, setQRCodeValues] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };
  const handleRedirect = (PatientId) => {
    navigate(`/patients/viewpatient/${PatientId}`, {
      state: { from: "dashboard" },
    });
  };

  const input = {
    pageNo: currentPage,
    pageSize: 10,
    searchQuery: searchTerm,
  };

  useEffect(() => {
    dispatch(getRecentVisitsApi(input));
  }, [dispatch, currentPage, searchTerm]);

  console.log("QR Code Values", qrCodeValues);
  // const data1 = [
  //   {
  //     name: "Jan",
  //     male: 4000,
  //     female: 2400,
  //     amt: 2400,
  //   },
  //   {
  //     name: "Feb",
  //     male: 3000,
  //     female: 1398,
  //     amt: 2210,
  //   },
  //   {
  //     name: "Mar",
  //     male: 2000,
  //     female: 9800,
  //     amt: 2290,
  //   },
  //   {
  //     name: "Apr",
  //     male: 2780,
  //     female: 3908,
  //     amt: 2000,
  //   },
  //   {
  //     name: "May",
  //     male: 1890,
  //     female: 4800,
  //     amt: 2181,
  //   },
  //   {
  //     name: "Jun",
  //     male: 2390,
  //     female: 3800,
  //     amt: 2500,
  //   },
  //   {
  //     name: "Jul",
  //     male: 3490,
  //     female: 4300,
  //     amt: 2100,
  //   },
  //   {
  //     name: "Aug",
  //     male: 2780,
  //     female: 3908,
  //     amt: 2000,
  //   },
  //   {
  //     name: "Sep",
  //     male: 1890,
  //     female: 4800,
  //     amt: 2181,
  //   },
  //   {
  //     name: "Oct",
  //     male: 2390,
  //     female: 3800,
  //     amt: 2500,
  //   },
  //   {
  //     name: "Nov",
  //     male: 3490,
  //     female: 4300,
  //     amt: 2100,
  //   },
  //   {
  //     name: "Dec",
  //     male: 2780,
  //     female: 3908,
  //     amt: 2000,
  //   },
  // ];

  // const items = [
  //   {
  //     label: <a href="">Edit</a>,
  //     key: "0",
  //   },
  //   {
  //     label: <a href="">Delete</a>,
  //     key: "1",
  //   },
  // ];
  // const content = (record) => (
  //   <div className="popover-patient">
  //     <div className="profile-info">
  //       <Space Space="small">
  //         <img src={patientimg} className="profile-info-single" alt="" />
  //         <div style={{ paddingLeft: "5px" }}>
  //           <p>{`${record.firstName} ${record.lastName}`}</p>
  //           <span>
  //             <FormattedMessage id="PatientList.UserId" />:{" "}
  //             <b>{record.userId}</b>
  //           </span>
  //         </div>
  //       </Space>
  //     </div>
  //     <Card>
  //       <Row>
  //         <Col span={18}>
  //           <h4>
  //             <b>
  //               <FormattedMessage id="PatientList.PatientCard" />
  //             </b>
  //           </h4>
  //           <Space className="mt-10">
  //             <Button icon={<PrinterOutlined />} shape="round">
  //               <FormattedMessage id="PatientList.Print" />
  //             </Button>
  //             <Button icon={<DownloadOutlined />} shape="round">
  //               <FormattedMessage id="PatientList.Download" />
  //             </Button>
  //           </Space>
  //         </Col>
  //         <Col span={6}>
  //           <div className="qrcode-list">
  //             <QRCode
  //               errorLevel="H"
  //               icon={logo}
  //               bgColor="#fff"
  //               value={`Name:${record.firstName} ${record.lastName}
  //                       Age:${record.age} Gender:${record.gender}
  //                       Id:${record.patientId}`}
  //               color="#00529b"
  //               size={120}
  //             />
  //           </div>
  //         </Col>
  //       </Row>
  //     </Card>
  //     <div className="call-info">
  //       <ul>
  //         <li>
  //           <a href="" className="info-bg">
  //             <i className="iconsminds-smartphone-3"></i>
  //             {`${record.countryCode} ${record.mobileNo}`}
  //           </a>
  //         </li>
  //         <li>
  //           <a href="" className="info-bg1">
  //             <i className="iconsminds-envelope"></i> {record.emailId}
  //           </a>
  //         </li>
  //       </ul>
  //     </div>
  //   </div>
  // );

  return (
    <>
      <div className="feature-view">
        <div className="title-heading">
          <h2>
            <FormattedMessage id="Common.Dashboard" />
          </h2>
        </div>
        {/* <div className="chart-area">
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Card>
                <Row>
                  <Col span={17}>
                    <h2>Screening Count</h2>
                    <p>User count by time visited yearly</p>
                  </Col>
                  <Col span={7} className="text-right">
                    <Select
                      defaultValue="year"
                      options={[
                        {
                          value: "daily",
                          label: "Daily",
                        },
                        {
                          value: "year",
                          label: "Yearly",
                        },
                        {
                          value: "month",
                          label: "Monthly",
                        },
                        {
                          value: "quater",
                          label: "Quaterly",
                        },
                        {
                          value: "half",
                          label: "Half-yearly",
                        },
                      ]}
                    />
                  </Col>
                </Row>
                <ResponsiveContainer
                  width="100%"
                  height={400}
                  className="mt-10"
                >
                  <BarChart
                    width={500}
                    height={300}
                    data={data1}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="male" stackId="a" fill="#00529B" />
                    <Bar dataKey="female" stackId="a" fill="#F250EB" />
                  </BarChart>
                </ResponsiveContainer>
              </Card>
            </Col>
          </Row>
        </div> */}

        <div className="table-top">
          <Row>
            <Col xs={24} md={4} lg={4}>
              <h3>
                <FormattedMessage id="ChyroDashboard.RecentVisits" />
              </h3>
            </Col>
            <Col xs={24} md={20} lg={20} className="text-right">
              <Space size="middle">
                <Search
                  size="large"
                  placeholder={intl.formatMessage({
                    id: "ChyroDashboard.Searchbypatientname",
                  })}
                  allowClear
                  onSearch={handleSearch}
                  style={{
                    width: 280,
                  }}
                />
                {/* <Button
                  icon={<ControlOutlined />}
                  size="large"
                  className="filter-icon"
                />
                <Button
                  icon={<FileExcelOutlined />}
                  size="large"
                  className="filter-icon"
                />
                <Button
                  type="primary"
                  size="large"
                  icon={<UserAddOutlined />}
                  onClick={showModal}
                >
                  Add Patients
                </Button> */}
              </Space>
            </Col>
          </Row>
        </div>
        <Table
          className="mt-15 patientlisttable"
          dataSource={RecentVisitsData}
          scroll={{
            x: 1000,
          }}
          size="small"
          pagination={{
            current: currentPage,
            total: totalPages * 10,
            onChange: handlePageChange,
          }}
          locale={{
            emptyText: (
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 80,
                }}
                description={intl.formatMessage({ id: "Common.NoData" })}
              />
            ),
          }}
        >
          <Column
            title={<FormattedMessage id="PatientList.PatientName" />}
            dataIndex="patientname"
            key="patientname"
            render={(_, record) => (
              <Space
                className="patientlink"
                size="small"
                onClick={() => handleRedirect(record.patientId)}
                style={{ cursor: "pointer", color: "red" }}
              >
                <img src={patientimg} className="patientimg-single" alt="" />
                {`${record.firstName} ${record.lastName}`}
              </Space>
            )}
          />
          <Column
            title={<FormattedMessage id="PatientList.UserId" />}
            dataIndex="userId"
            key="userId"
          />
          <Column
            title={<FormattedMessage id="PatientList.VisitId" />}
            dataIndex="patientVisitId"
            key="patientVisitId"
          />
          <Column
            title={<FormattedMessage id="ChyroDashboard.VisitDate" />}
            key="timedate"
            render={(_, record) => (
              <Space size="small">
                {moment(record?.patientVisitedDate).format(
                  "DD-MMM-YYYY  HH:mm"
                )}
              </Space>
            )}
          />
          <Column
            title={<FormattedMessage id="Clients.Weight" />}
            dataIndex="totalBodyWeight"
            key="totalBodyWeight"
          />
          <Column
            title={<FormattedMessage id="ViewPatient.BMI" />}
            dataIndex="bmi"
            key="bmi"
          />
          <Column
            title={<FormattedMessage id="ViewPatient.BMR" />}
            dataIndex="bmr"
            key="bmr"
          />
          <Column
            title={<FormattedMessage id="PatientList.Age" />}
            dataIndex="age"
            key="age"
          />
          <Column
            title={<FormattedMessage id="PatientList.Gender" />}
            dataIndex="gender"
            key="gender"
          />
          <Column
            title={<FormattedMessage id="ChyroDashboard.HealthScore" />}
            dataIndex="healthscore"
            key="healthscore"
            width={150}
            render={(_, record) => (
              <Space size="middle">
                <HealthScore record={record.healthscore} />

                {/* <Popover content={content(record)} placement="rightTop">
                  <Button
                    type="primary"
                    shape="round"
                    //onClick={() => setQRCodeValues(record)}
                    icon={<QrcodeOutlined />}
                  >
                    {<FormattedMessage id="Common.QRCode" />}
                  </Button>
                </Popover> */}
              </Space>
            )}
          />
          {/* <Column
            title="Actions"
            key="action"
            width={70}
            render={(_, record) => (
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </a>
              </Dropdown>
            )}
          /> */}
        </Table>
        {/* {qrCodeValues && <QRCodeGenerator values={qrCodeValues} />} */}

        {/* <Modal
          title="Add Patient"
          centered={true}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={800}
          footer={[
            <Button type="default" onClick={handleCancel} size="large">
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk} size="large">
              Save
            </Button>,
          ]}
        >
          <Form
            name="basic"
            layout="horizontal"
            className="form-new-kolotech"
            size="large"
            labelCol={{
              span: 6,
              xs: 8,
              md: 6,
              lg: 6,
              xl: 6,
            }}
            wrapperCol={{
              span: 18,
              xs: 16,
              md: 16,
              lg: 17,
              xl: 17,
            }}
            initialValues={{
              lineType: "Instruction",
              remember: true,
            }}
          >
            <Form.Item
              label="Patient Name"
              name="PatientName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Enter Patient Name" />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="phonenumber"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Enter Phone Number" />
            </Form.Item>
            <Form.Item
              label="E-Mail"
              name="mail"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Enter Email Address" />
            </Form.Item>
            <Form.Item
              label="Date of Birth"
              name="dob"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="Gender" name="gender">
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>Male</Radio>
                <Radio value={2}>Female</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Address" name="adress">
              <Row gutter={16}>
                <Col span={12}>
                  <Input placeholder="Flat no/Street Name" />
                </Col>
                <Col span={12}>
                  <Input placeholder="Provience" />
                </Col>
              </Row>
              <Row gutter={16} className="mt-10">
                <Col span={12}>
                  <Input placeholder="Postal Code" />
                </Col>
                <Col span={12}>
                  <Select defaultValue={"US"}>
                    <Option value="US">US</Option>
                    <Option value="Canada">Canada</Option>
                  </Select>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Modal> */}
      </div>
    </>
  );
}

export default ChyroDashboard;
