import { content, Button, Space, Col, Tag } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

function HealthScore({ record }) {
  const intl = useIntl();
  return (
    <Col>
      <Space size="middle">
        {record >= 8 ? (
          <Tag
            style={{
              backgroundColor: "#09A334",
              border: "solid 1px #09A334",
              color: "#fff",
            }}
          >
            <FormattedMessage id="Common.Healthy" />
          </Tag>
        ) : record >= 6 && record < 8 ? (
          <Tag
            style={{
              backgroundColor: "#FFA500",
              border: "solid 1px #FFA500",
              color: "#fff",
            }}
          >
            <FormattedMessage id="Common.Average" />
          </Tag>
        ) : record <= 6 && record >= 1 ? (
          <Tag
            style={{
              backgroundColor: "#EB5757",
              border: "solid 1px #EB5757",
              color: "#fff",
            }}
          >
            <FormattedMessage id="Common.Serious" />
          </Tag>
        ) : (
          <Tag
            style={{
              backgroundColor: "#999",
              border: "solid 1px #999",
              color: "#fff",
            }}
          >
            <FormattedMessage id="Common.NoData" />
          </Tag>
        )}
      </Space>
    </Col>
  );
}
export default HealthScore;
