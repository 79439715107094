import SinglePatientView from "../features/Patients/SinglePatientView/SinglePatientViewSlice";

import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import clientReduces from "../features/Clients/ClientList/ClientListSlice";
import ViewClientReduces from "../features/Clients/ViewClient/ViewClientSlice";
import GlobalSpinner from "../features/CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import patientlistReduces from "../features/Patients/PatientList/PatientListSlice";
import ViewPatientReduces from "../features/Patients/Viewpatient/ViewPatientSlice";
import patientvisitDetailsReduces from "../features/Patients/PatientVisitDetails/PatientVisitDetailsSlice";
import authentication from "../features/OnBoarding/AuthenticationSlice";
import dashboardReduces from "../features/Dashboard/DashboardSlice";
import thermalPostures from "../features/CommonComponents/ThermalPostures/ThermalPosturesSlice";
import ResultPopup from "../features/CommonComponents/ResultPopup/ResultPopupSlice";
import vistThermalPostureCompare from "../features/Patients/VistThermalPostureCompare/VistThermalPostureCompareSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,

    singlePatientView: SinglePatientView,

    clientList: clientReduces,
    ViewClient: ViewClientReduces,
    GlobalSpinner: GlobalSpinner,
    patientlist: patientlistReduces,
    Viewpatient: ViewPatientReduces,
    patientvisitDetails: patientvisitDetailsReduces,
    authentication: authentication,
    dashboard: dashboardReduces,
    thermalPostures: thermalPostures,
    ResultPopup: ResultPopup,
    vistThermalPostureCompare: vistThermalPostureCompare,
  },
});
