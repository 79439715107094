import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  thermalImageNames: [],
};

export const ThermalPosturesSlice = createSlice({
  name: "thermalPostures",
  initialState,
  reducers: {
    getAllThermalImageNames: (state, action) => {
      state.thermalImageNames = action.payload;
    },
  },
});

export const { getAllThermalImageNames } = ThermalPosturesSlice.actions;

export default ThermalPosturesSlice.reducer;
